// Before deploy:
// if site id deploy using that
// else list sites with name filter and save site_id
// else createSite

// Deploy:
// Disable button:(setDeploymentStatus('deploying'))
// Activate button when site is done or an error occured
// Get status using site id every 5 seconds


import {netlifyDeployRequest, netlifyDeploySuccess, netlifyDeployFailure} from '../actions/auth';
import saveAction from './authSaveData'
import createSiteAction from './netlifyCreateSite'
const axios = require('axios');

function netlifyDeploy(netlifySiteId, domain, tld, accessToken, buildStatus, data, site_id) {
    console.log('deploying netlify state')
    const domainEnding = tld === 'da' ? 'dk' : 'com'

    return dispatch => {

        if(!buildStatus || buildStatus !== 'building') {
            console.log('dispatching deploy request')
            dispatch(netlifyDeployRequest())

            if(netlifySiteId) {

                // Deployg using netlify_site_id

                console.log('id', netlifySiteId)

                axios.post('/.netlify/functions/netlify', {
                    method: 'post',
                    payload: {}, 
                    endpoint: 'builds', 
                    netlifySiteId: netlifySiteId
                })
                .then((response) => {
                    if(response.error) {
                        console.log('Netlify deploy site throwing error: ', response.error)
                        throw(response.error);
                    } else {
                        console.log('Netlify deploy successfull: ', response.data)
                        dispatch(saveAction(accessToken, {}, null, 'PUT', null, null, true))
                        dispatch(netlifyDeploySuccess(response.data));
                    }
                })
                .catch((err) => {
                    console.log('Netlify deployment failure: ', err.response)
                    dispatch(netlifyDeployFailure(err.response.status))
                })
            } else if(domain) {

                // Fetch netlify site data using domain
                axios.post('/.netlify/functions/netlify', {
                    method: 'get',
                    payload: {}, 
                    endpoint: `?name=${domain}-worksiter-${domainEnding}`, 
                })
                .then((response) => {
                    if(response.error) {
                        console.log('Netlify deploy throwing error: ', response.error)
                        throw(response.error);
                    }

                    if (response.data[0].site_id) {

                        // Save Netlify site id to db

                        console.log('saving netlify site id: ', response.data[0].site_id)
                        dispatch(saveAction(accessToken, {}, response.data[0].site_id, 'PUT', null, null, true))
                        
                        
                    } else {
                        dispatch(saveAction(accessToken, {...data}, null, 'PUT', null, null, true))
                    }

                    console.log('Netlify deploy successfull:  ', response.data[0].state)
                    dispatch(netlifyDeploy(netlifySiteId, domain, tld, accessToken, buildStatus));
                    
                })
                .catch((err) => {
                    console.log('Deploy site  : failure: ', err.response)
                    dispatch(netlifyDeployFailure(err.response))
                    // dispatch(createSiteAction(site_id, domain, tld, accessToken))
                })

            } else {
                console.log('returning with no luck')
                return
            }
        }

    }
}

export default netlifyDeploy