import React from 'react'
import servicesListStyles from './servicesList.module.scss'
import CheckMark from './checkmark'

export const ServicesListModern = (props) => {
      
  const serviceTypes = props.services;

    return (
        <section className={["section", servicesListStyles.sectionPadding, servicesListStyles.bgSway].join(' ')}>
            <div className={servicesListStyles.bgSwayInner}  > 
              <div className="container" > 
                  <div className={["row middle-xs", servicesListStyles.content].join(' ')}>
                    <div className="col col-xs-12 text-center">
                      <div style={{backgroundColor: props.lightColor, padding: '5rem 3rem', borderRadius: '1rem'}}>
                      { props.title ? 
                          <h2>{props.title}</h2>
                      : null }
                      {props.services ?
                      <div className="center flex-wrap col-xs-12 col-sm-10 col-sm-offset-1">
                        {serviceTypes.map((serviceType, i) => {
                          return <p key={i} className={servicesListStyles.service}><CheckMark {...props} primary={props.primary} />{serviceType}</p>
                        })}
                      </div>
                      : null }
                      </div>
                    </div>
                  </div>
              </div>
           </div>
        </section> 
    )
}

export default ServicesListModern