import React from 'react'
import servicesListStyles from './servicesList.module.scss'
import CheckMark from './checkmark'

export const ServicesList = (props) => {
      
  const serviceTypes = props.services;

    return (
        <section className={[servicesListStyles.sectionPadding].join(' ')} style={{backgroundColor: props.lightColor}}>
            <div className="container"> 
                <div className="row middle-xs">
                  { props.title ? 
                    <div className="col col-xs-12 text-center">
                      <h2>{props.title}</h2>
                    </div>
                  : null }
                  {props.services ?
                  <div className="center flex-wrap col-xs-12 col-sm-10 col-sm-offset-1">
                    {serviceTypes.map((serviceType, i) => {
                      return <p key={i} className={servicesListStyles.service}><CheckMark {...props} primary={props.primary} />{serviceType}</p>
                    })}
                  </div>
                  : null }
                </div>
            </div>
        </section> 
    )
}

export default ServicesList