import React from 'react'
import TextBlock from './textBlock/textBlock'
import whyUsStyles from './whyUs.module.scss'

const WhyUsModern = ({title, text, textBlocks, secondary, lightColor}) => {
    
    return (
        <section className="section">
            <div className="container">
                <div className="row text-center center-xs ">
                    <div className="col col-xs-12 col-md-10 col-lg-8 space-xs-up">
                      {title ? <h2>{title}</h2> : null }
                      {text ?  <p>{text}</p> : null }
                    </div>
                    <div className="col col-xs-12 space-xs-up">
                      {/* <hr className={whyUsStyles.line} style={ secondary ? {color: secondary} : {color: '#ff7b00'} } />*/}
                    </div>
                    { textBlocks ? <TextBlock textBlocks={textBlocks} secondary={secondary} lightColor={lightColor}/> : null }
                </div>
            </div>
        </section>
    )
}

export default WhyUsModern