
import React from "react"

import { useIntl } from "gatsby-plugin-intl"


import Form from '../../../components/hoc/Form/Form'

const SearchCloseHandler = ({ searchImageHandler, searchCloseHandler, searchTerm, loading, success, changeHandler }) => {
    
    const i18n = useIntl().formatMessage

    return (
        <Form 
            loading={loading}
            success={success}
            submitHandler={searchImageHandler}
            submitText={ i18n({ id: "general.search" }) }
            formElements={[{ type: 'text', name: 'image', value: searchTerm, label: i18n({ id: "general.search_unsplash" }), help: i18n({ id: "pages.dashboard.browser.image_search_help" }), required: false }]}
            cancelBtn={true}
            cancelHandler={searchCloseHandler}
            changeHandler={changeHandler}
        />
    )
}

export default SearchCloseHandler