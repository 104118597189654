import React from 'react'
import TextBlock from './textBlock/textBlock'
import whyUsStyles from './whyUs.module.scss'

const WhyUs = ({title, text, textBlocks, secondary}) => {
    return (
        <section className={whyUsStyles.sectionPadding}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12 col-lg-9 space-xs-up text-left">
                      {title ? <h2>{title}</h2> : null }
                      {text ?  <p>{text}</p> : null }
                    </div>
                    <div className="col col-xs-12 space-xs-up">
                      <hr className={whyUsStyles.line} style={ secondary ? {color: secondary} : {color: '#ff7b00'} } />
                    </div>
                    { textBlocks ? <TextBlock textBlocks={textBlocks }/> : null }
                </div>
            </div>
        </section>
    )
}

export default WhyUs
