import React from 'react'
import { Link } from "gatsby"

const AnyLink = ({buttonText, link, classes, style, onClick}) => {

    return (
       buttonText ?
            link && link.link_type === 'external_link' && link.external_link ?
              <a 
                href={link.external_link}
                className={classes} 
                style={style}
                target="_blank"
                rel="noopener noreferrer"
              >
                {buttonText}
              </a> 
            : link && link.link_type === 'internal_link' && link.internal_link ?
              <Link 
                to={link.internal_link.includes('/') ? link.internal_link : '/' + link.internal_link}
                className={classes} 
                style={style}
              >
                {buttonText}
              </Link>
            : 
            <button 
                className={classes} 
                onClick={onClick} 
                style={style}
                >
                {buttonText}
            </button>
          : null
    )
}

export default AnyLink