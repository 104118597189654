import React, { useState, useEffect } from "react"

import { useIntl } from "gatsby-plugin-intl"

import Modal from "../../../components/hoc/Modal/Modal";
import Form from '../../../components/hoc/Form/Form'
import ImageSearchForm from './ImageSearchForm'

import SectionEditorStyles from './SectionEditor.module.scss'
import cloneDeep from "lodash.clonedeep";

const SectionEditor = ({ show, title, description, editCloseHandler, formElements, saveSection, industry, pages }) => {
    const axios = require('axios');
    
    const i18n = useIntl().formatMessage
    
    const [showImageSearcher, setShowImageSearcher] = useState(null)
    const [imageResults, setImageResults] = useState(null)
    const [searchTerm, setSearchTerm] = useState('')
    const [queryPage, setQueryPage] = useState(1)
    const [formEls, setFormEls] = useState(formElements)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [showMoreVisible, setShowMoreVisible] = useState(true)
    const keyWords = i18n({ id: `search-terms.${industry}` }).split(', ')

    useEffect(() => {
        console.log('formElements', formElements)
        setFormEls(formElements)
    }, [formElements])

    const imageChangeHandler = (i, e, sub) => {
        e.preventDefault()
        console.log('SUBFORMELS', sub)

        let newFormEls = cloneDeep(formEls)

        newFormEls.map((el, i) => {
            el.value = sub[el.name]
        })

        console.log(newFormEls)
        setFormEls(newFormEls)
        setShowImageSearcher(i)
    }

    const searchImageHandler = (submission, searchPage) => {
        let url = ''
        setLoading(true)
        if(submission.image) {
            setSearchTerm(submission.image)
            url = submission.image  
        } else {
            setSearchTerm(submission)
            url = submission
        }
        let imagesTemp
        if(url !== searchTerm) {
            setImageResults(null)
            imagesTemp = null
        } else {
            imagesTemp = imageResults
        }
        axios.get(`https://api.unsplash.com/search/photos?query=${url}&page=${searchPage}`, {
            // headers: withAuth()
            headers: {
                Authorization: `Client-ID 9799a34eb2e987c6ae1d25c72f091a8ae56330c3fa052570e023f25bd88c14fa`
            }
        })
        .then((res) => {

            if(res.data.results.length < 10) {
                setShowMoreVisible(false)
            } else {
                setShowMoreVisible(true)
            }
            const imgArr = imagesTemp || []
            console.log('Results', [...imgArr, ...res.data.results])
            setLoading(false)
            setSuccess(true)
            setImageResults([...imgArr, ...res.data.results])
        })
        .catch((err) => {
            setLoading(false)
            console.log(err)
        });
    }

    const searchCloseHandler = () => {
        setShowImageSearcher(null)
    }

    const showMoreHandler = () => {
        setQueryPage(queryPage + 1)
        searchImageHandler(searchTerm, queryPage + 1)
    }

    const selectImageHandler = (url) => {
        console.log('Form elements', formEls)
        let newFormEls = formEls
        const index = formEls.findIndex((el) => el.type === 'image')

        newFormEls[index].value = url
        
        newFormEls.push({name: 'image_url', value: url})
        setFormEls(newFormEls)
        
        setShowImageSearcher(null)
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
          e.target.focus()
        }
      }

    const imageSearchChangeHandler = () => {
        console.log('chaning')
        setLoading(false)
        setSuccess(false)
    }


    return (
        <Modal show={show} closeHandler={editCloseHandler}>
            {showImageSearcher ? 
            <button className="btn btn-unstyled text-blue" onClick={() => searchCloseHandler()}>← { i18n({ id: "general.back" }) }</button>
            : null }
            <h2 className={["space-xs-up", showImageSearcher ? "no-mt" : null].join(' ')}>{ title } {showImageSearcher ? <span className="text-normal">- {formEls[showImageSearcher].label}</span> : null}</h2>
            {description ? <p className="space-xs-up">{description}</p> : null }
            {showImageSearcher ? 
                <div>
                    <p className="mb-small">{ i18n({ id: "general.suggested_keywords" }) }:</p>
                    { keyWords.map((w, i) => 
                        <button key={i} className={["", SectionEditorStyles.tagBtn].join(' ')} onClick={() => searchImageHandler(w)}>{w}</button>
                    )}
                </div>
            : null }
            { !showImageSearcher && show ?
                <Form 
                    submitHandler={saveSection}
                    submitText={ i18n({ id: "general.save" }) }
                    formElements={formEls}
                    cancelBtn={true}
                    cancelHandler={editCloseHandler}
                    imageChangeHandler={imageChangeHandler}
                    pages={pages}
                    // fieldChange={fieldChange}
            />
            : null }
            {showImageSearcher !== null ?
                <ImageSearchForm 
                    loading={loading}
                    success={success}
                    searchImageHandler={searchImageHandler} 
                    searchCloseHandler={searchCloseHandler} 
                    searchTerm={searchTerm || ''}
                    changeHandler={imageSearchChangeHandler}/>
            : null }
            {showImageSearcher && imageResults ?
            <div className="flex flex-wrap justify top-xs space-top">
            { imageResults.map((image, i) => 
                <div key={i} className={SectionEditorStyles.imageWrapper} onClick={() => selectImageHandler(image.urls.regular)} onKeyDown={handleKeyDown} role="button" tabIndex={0} >
                    <img  src={image.urls.small} alt={`Unsplash - ${image.user.name}`}/>
                    <a href={image.user.links.html} target="_blank" rel="noopener noreferrer">
                        <p className={SectionEditorStyles.credits}>Unsplash - {image.user.name}</p>
                    </a>
                </div>
            ) }
            </div>
            : null }
            {showImageSearcher !== null && showMoreVisible && imageResults?
            <div className="text-center btn-green">
                <Form 
                    loading={loading}
                    submitHandler={showMoreHandler}
                    submitText={ i18n({ id: "general.show_more" }) }
                    formElements={[]}
                />
                {/* <button className="btn btn-secondary mt" onClick={() => showMoreHandler()}>Vis flere</button> */}
            </div>
            : null }
      </Modal>
    )
}

export default SectionEditor