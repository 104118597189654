import React from 'react'
import BrowserStyles from './Browser.module.scss'
import LoadingSpinner from '../../../components/hoc/loadingSpinner/LoadingSpinner'
import slugify from '../../../components/hoc/slugify/Slugify'
import { useEffect } from 'react'
import { useState } from 'react'

const Browser = ({i18n, classes, data, content, website, profile, currentPage, changePageHandler, children, pageEditHandler, previewClickHandler, preview, saveSiteClickHandler, cancelClickHandler, changesSaved, dataSavePending, dashboardJoyrideHandler, changesPublished}) => {

  const tabsRef = React.createRef()
  const [tabsHeight, setTabsHeight] = useState()

  useEffect(() => {
    const toolBarHeight = window.innerWidth < 960 ? 3.5 : 4.1875
    setTabsHeight((tabsRef.current.clientHeight / 16) + toolBarHeight)
  }, [])

    return (
        <div className={["row text-center center-xs browser", classes].join(' ')}>
            <div className="col col-xs-12">
                <div className={[BrowserStyles.browser, preview ? BrowserStyles.preview : null, 'card'].join(' ')} style={tabsHeight ? {paddingTop: tabsHeight + 'rem'} : null}>
                  <div className={BrowserStyles.toolBar}>
                    <div className={BrowserStyles.searchField}>
                      <p className="small">{`www.${website.domain_name}.worksiter.${profile.country === 'da' ? 'dk' : profile.country === 'en' ? 'com' : profile.country === 'no' ? 'com' : profile.country}${content.page[currentPage].slug !== '__home__' ? '/' + slugify(content.page[currentPage].name) : '' }`}</p>
                      <button className={BrowserStyles.preview} onClick={previewClickHandler}>
                      
                      <span className={[BrowserStyles.iconPreview, preview ? BrowserStyles.previewing : null].join(' ')}></span>
                      { preview ? i18n({ id: "pages.dashboard.browser.edit"}) : i18n({ id: "pages.dashboard.browser.preview"}) }
                    </button >

                      <button onClick={dashboardJoyrideHandler} className={BrowserStyles.help}>
                      <span className={BrowserStyles.icon}>?</span>
                      { i18n({ id: "pages.dashboard.browser.help"}) }
                    </button>
                    </div>
                    <div className={BrowserStyles.saveButtons}>
                      <button className="text-red btn btn-unstyled mr" onClick={cancelClickHandler}>{ i18n({ id: "general.cancel"}) }</button>
                      <button className="btn btn-secondary save" onClick={saveSiteClickHandler} disabled={!profile.active}><LoadingSpinner loading={dataSavePending} >{ i18n({ id: "general.save"}) }</LoadingSpinner></button>
                    </div>
                    <div ref={tabsRef} className={[BrowserStyles.tabs, "pages"].join(' ')}>
                      {content.page.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0)).map((page, i) =>

                      i < 7 ?
                        <div key={i} className={[BrowserStyles.tab, currentPage === i ? BrowserStyles.activeTab : null].join(' ')}>
                            { i > 0 ? 
                                <button className={BrowserStyles.delete} onClick={() => pageEditHandler('delete', i)}><span className="hidden">Delete</span></button> 
                            : null }
                            <button onClick={() => changePageHandler(i)}>{page.name}</button>
                            {/* {i > 0 ?  */}
                            <button className={BrowserStyles.edit} onClick={() => pageEditHandler('edit', i)}><span className="hidden">Edit</span><span className={BrowserStyles.icon}></span></button>
                            {/* : null } */}
                        </div>
                        : null
                      )}
                      {content.page.length < 6 ?
                      <button className={[BrowserStyles.tabPlus, 'small'].join(' ')} onClick={() => pageEditHandler('add', 0)}> { i18n({ id: "pages.dashboard.browser.add_new_page"}) } +</button>
                      : null }
                    </div>
                  </div>
                  
                  <div className={[BrowserStyles.statusCard, 'text-left'].join(' ')}>
                  <h5>{ i18n({ id: "pages.dashboard.browser.status"}) }</h5>
                  <ul className={[BrowserStyles.statusList, "list-unstyled"].join(' ')}>
                    <li className={changesPublished ? BrowserStyles.greenIndicator : BrowserStyles.orangeIndicator}>
                      { changesPublished ? i18n({ id: "pages.dashboard.browser.changes_published"}) : i18n({ id: "pages.dashboard.browser.changes_unpublished"}) }</li>
                    <li className={changesSaved ? BrowserStyles.greenIndicator : BrowserStyles.orangeIndicator}>{ changesSaved ? i18n({ id: "pages.dashboard.browser.changes_saved"}) :  i18n({ id: "pages.dashboard.browser.changes_unsaved"})}</li>
                    <li className={profile && profile.active ? BrowserStyles.greenIndicator : BrowserStyles.redIndicator}>{profile && profile.active ? i18n({ id: "pages.dashboard.browser.account_active"}) : i18n({ id: "pages.dashboard.browser.account_inactive"})}</li>
                  </ul>
                </div>

                {children}

              </div>
            </div>
          </div>
    )
}

export default Browser