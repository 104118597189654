import React from 'react'
import contactStyles from './contact.module.scss'
import PhoneIcon from './phoneIcon'
import {getContrastColor} from '../../hoc/helpers'
import EmailIcon from '../../hoc/emailIcon'


const Contact = ({setFullname, setPhone, setEmail, setMessage, title, text, phone, primary, secondary, submitAssignmentHandler, sendButtonText, lightColor}) => {

    return (
        <section className={[contactStyles.sectionPadding].join(' ')} style={{ backgroundColor: lightColor}}>
            <div className="container">
                <div className={["row flex middle-xs text-left", contactStyles.contactWrapper].join(' ')}>
                    <div className={["col", "col-xs-12", "col-lg-6", "space-xs-up", contactStyles.conpanyContact].join(' ')}>
                    { title ? <h2 className="space-xs-up">{ title}</h2> : null }
                        {  text ? <p>{ text}</p> : null }
                        {  phone ? <a style={  primary ? {color:  primary} : {color: '#0283f1'} } href={`tel:${ phone}`}><PhoneIcon secondary={secondary} />{ phone}</a> : null }
                    </div>
                    <div className="col col-xs-12 col-lg-6">
                        <form className={contactStyles.form}>
                            <input type="text" placeholder="Navn" className={contactStyles.input} onChange={(event) => setFullname({fullName: event.target.value})}/>
                            <input type="tel" placeholder="Telefonnummer" className={contactStyles.input} onChange={(event) => setPhone({phone: event.target.value})}/>
                            <input type="email" placeholder="Email" className={contactStyles.input} onChange={(event) => setEmail({email: event.target.value})}/>
                            <textarea name="assignment" cols="30" rows="8"  placeholder="Beskriv opgaven..." className={contactStyles.input} onChange={(event) => setMessage({message: event.target.value})}/>
                            <button className={contactStyles.sendButton} style={{ backgroundColor:  primary ?  primary : '#ff7b00', color:  getContrastColor( primary)} } onClick={ submitAssignmentHandler}>
                                { sendButtonText || <EmailIcon/>}
                            </button>
                        </form>
                        </div>
                </div>
            </div>
        </section> 
    )
}

export default Contact

