import React from 'react'
import Img from 'gatsby-image'
import headerStyles from './headerClassic.module.scss'
import {getContrastColor} from '../../hoc/helpers'
import AnyLink from '../../hoc/anyLink/anyLink'

const HeaderClassic = ({primary, image, title, text, buttonText, showModal, altText, link}) => {

    return (
      <section className={headerStyles.headerWrapper}>
        {image && image.childImageSharp ?
          <Img fluid={image.childImageSharp.fluid} alt={altText} loading="eager" className={headerStyles.headerImage} />
          :
          <img className={headerStyles.headerImage} src={image} alt={altText} />
          }
        <div className={[headerStyles.headerContent, "col col-xs-10 col-xs-offset-1 text-center"].join(' ')}>
          { title ? <h1 className={["text-center", "text-white", headerStyles.sectionName].join(' ')}>{title }</h1> : null }
          { text ? <p className="text-center text-white">{text}</p> : null }
          <AnyLink 
            buttonText={buttonText}
            link={link}
            classes={headerStyles.button}
            style={{ backgroundColor: primary ? primary : '#ff7b00', color: getContrastColor(primary)}}
            onClick={showModal}
            />
          </div>
      </section>
    )
}

export default HeaderClassic
