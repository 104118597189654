import React from 'react'
import textStyles from './text.module.scss'

const Text = ({title, text}) => {
    return (
        <section className={["section", textStyles.sectionPadding].join(' ')}> 
            <div className="container"> 
                <div className="row">
                    <div className="col col-xs-12 col-md-10 col-md-offset-1 col-xl-8 col-xl-offset-2">
                        {title ? <h2>{title}</h2> : null }
                        {text ? <p>{text}</p> : null }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Text