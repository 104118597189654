import React from 'react'
import textWithImageLeftStyles from './textWithImageLeft.module.scss'
import Img from 'gatsby-image'
import AnyLink from '../../hoc/anyLink/anyLink'

export const TextWithImageLeft = ({image, text, textTitle, showModal, secondary, buttonText, altText, link}) => {

    return (
        <section className={textWithImageLeftStyles.sectionPadding}>
            <div className="container">
                <div className="row middle-xs start-xs">
                    <div className="col col-xs-12 col-md-6">
                        { textTitle ? <h3 className={textWithImageLeftStyles.textTitle}>{textTitle}</h3> : null }
                        { text ? <p className={textWithImageLeftStyles.text}>{text}</p> : null }
                        <AnyLink 
                            buttonText={buttonText}
                            link={link}
                            classes="btn btn-unstyled text-bold"
                            style={{ color: secondary ? secondary : '#ff7b00'}}
                            onClick={showModal}
                            />
                    </div>
                    <div className="col col-xs-12 col-md-6 first-xs last-md space-xs space-sm">
                    {image && image.childImageSharp ?
                        <Img fluid={image.childImageSharp.fluid} alt={altText} className={textWithImageLeftStyles.image} />
                        :
                        <img className={textWithImageLeftStyles.image} src={image} alt={altText} />  
                    }
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default TextWithImageLeft