import React from 'react'
import textWithImageRightStyles from './textWithImageRight.module.scss'
import Img from 'gatsby-image'
import AnyLink from '../../hoc/anyLink/anyLink'

export const TextWithImageRightModern = ({image, text, textTitle, showModal, secondary, buttonText, altText, link}) => {
    console.log('LINK ', link)
    return (
        <section className="section">
            <div className="container">
                <div className="row middle-xs start-xs between-md">
                    <div className="col col-xs-12 col-lg-6 space-xs space-sm space-md end-lg flex-lg">
                    {image && image.childImageSharp ?
                        <Img fluid={image.childImageSharp.fluid} alt={altText} className={textWithImageRightStyles.image} />
                        :
                        <img className={textWithImageRightStyles.image} src={image} alt={altText} /> 
                    }
                    </div>
                    <div className={"col col-xs-12 col-lg-5"}>
                        { textTitle ? <h3 className={textWithImageRightStyles.textTitle}>{textTitle}</h3> : null }
                        <p dangerouslySetInnerHTML={{__html: text}}></p>
                        <AnyLink 
                            buttonText={buttonText}
                            link={link}
                            classes="btn btn-unstyled text-bold"
                            style={{ color: secondary ? secondary : '#ff7b00'}}
                            onClick={showModal}
                            />
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default TextWithImageRightModern
