// Get deploy status on login:
// if site id get site using that
// else list sites with name filter and save site_id and use "state" from this call to set status
// if false, set deploy status to not deployed

import {netlifyPollingRequest, netlifyPollingSuccess, netlifyPollingFailure} from '../actions/auth';
import saveAction from './authSaveData'
const axios = require('axios');

function netlifyPolling(netlifySiteId, domain, tld, accessToken) {
    console.log('polling netlify state: ', netlifySiteId, domain, tld, accessToken)
    const domainEnding = tld === 'da' ? 'dk' : 'com'

    return dispatch => {

        dispatch(netlifyPollingRequest());

        const poller = (netlifySiteId, domain, tld, accessToken, timeout) => {

            const timout = setTimeout(() => {
                clearTimeout(timout)
                
                if(netlifySiteId) {
                    axios.post('/.netlify/functions/netlify', {
                        method: 'get',
                        endpoint: null,
                        payload: {}, 
                        netlifySiteId: netlifySiteId
                    })
                    .then((response) => {
                        console.log('RESPONSE FROM POLLING: ', response)
                        if(response.error) {
                            console.log('netlifyPollingSite throwing error: ', response.error)
                            throw(response.error);
                        }
                        if (response.data.data.state !== 'ready' && response.data.data.state !== 'current' && response.data.data.state !== 'error') {
                            dispatch(netlifyPollingSuccess(response.data.data));
                            poller(netlifySiteId, domain, tld, accessToken, 7500)    
                        } else {
                            
                            console.log('netlifyPollingSite successfull: ', response.data.data.state)
                            
                            dispatch(netlifyPollingSuccess(response.data.data));
                            return response.data.data.site_id;
                        }
                    })
                    .catch((err) => {
                        console.log('polling failure: ', err.response)
                        
                        axios.post('/.netlify/functions/netlify', {
                            method: 'get',
                            payload: {}, 
                            endpoint: `?name=${domain}-worksiter-${domainEnding}`, 
                        })
                        .then((response) => {
                            if(response.error) {
                                console.log('netlifyPollingSite throwing error: ', response.error)
                                throw(response.error);
                            }
                            console.log('netlifyPollingSite successfull: else ', response.data.data[0].state)
                            if (response.data.data[0].site_id) {
                                console.log('saving netlify site id: ', response.data.data[0].site_id)
                                dispatch(saveAction(accessToken, {}, response.data.data[0].site_id))
                                poller(response.data.data[0].site_id, domain, tld, accessToken, 7500)
                                
                            } else {
                                console.log('restart poller')
                                poller(netlifySiteId, domain, tld, accessToken, 7500)
                            }            
                        })
                        .catch((err) => {
                            console.log('Poll site  : failure: ', err.response)
                            dispatch(netlifyPollingFailure(err.response))
                        })
                    })
                } else if(domain) {
                    axios.post('/.netlify/functions/netlify', {
                        method: 'get',
                        payload: {}, 
                        endpoint: `?name=${domain}-worksiter-${domainEnding}`, 
                    })
                    .then((response) => {
                        if(response.error) {
                            console.log('netlifyPollingSite throwing error: ', response.error)
                            throw(response.error);
                        }
                        console.log('netlifyPollingSite successfull: else ', response.data.data[0].state)
                        
                        if (response.data.data[0].site_id) {
                            console.log('saving netlify site id: ', response.data.data[0].site_id)
                            dispatch(saveAction(accessToken, {}, response.data.data[0].site_id, 'PUT'))
                            poller(response.data.data[0].site_id, domain, tld, accessToken, 7500)
                            
                        } else {
                            console.log('restart poller')
                            poller(netlifySiteId, domain, tld, accessToken, 7500)
                        }       
                    })
                    .catch((err) => {
                        console.log('Poll site  : failure: ', err.response)
                        dispatch(netlifyPollingFailure(err.response))
                    })
                } else {
                    dispatch(netlifyPollingFailure())
                }
            }, timeout);
        }
        poller(netlifySiteId, domain, tld, accessToken, 0)
    }
}

export default netlifyPolling