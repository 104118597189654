import React from 'react'
import checkMarkStyles from './servicesList.module.scss'

const Checkmark = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="33.074" height="26.082" viewBox="0 0 33.074 26.082" className={checkMarkStyles.checkmark}>
            <path id="Path_47" data-name="Path 47" d="M5103.518,7570l9.825,11.165,16.205-20.066" transform="translate(-5099.989 -7557.585)" fill="none" style={ props.primary ? {stroke: props.primary} : {stroke: '#0283f1'} } strokeLinecap="round" strokeLinejoin="round" strokeWidth="5"/>
        </svg>

    )
}

export default Checkmark
