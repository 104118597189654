import React from 'react'
import codeStyles from './code.module.scss'

const Code = ({title, text, embed}) => {
    
    return (
        <section className={codeStyles.sectionPadding}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12 text-center space-xs-up">
                        {title ? <h2>{title}</h2> : null }
                        {text ? <p>{text}</p> : null }
                    </div>
                    <div className="col col-xs-12">
                    <div className={["embed-responsive", codeStyles.embed].join(' ')}>
                            <div dangerouslySetInnerHTML={{__html: embed }}></div> 
                        </div> 
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Code
