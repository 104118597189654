import React from 'react'
import contactSimpleStyles from './contactSimple.module.scss'
import PhoneIcon from './phoneIcon'
import EmailIcon from './emailIcon'

export const ContactSimple = (props) => {
      
    return (
        <section className={[contactSimpleStyles.sectionPadding].join(' ')} style={{backgroundColor: props.lightColor}}> 
            <div className="container"> 
                <div className="row middle-xs center center-xs">

                {props.phoneNumber ?
                  <div className="col col-xs-12 col-md-4 col-lg-3 space-xs">
                    <PhoneIcon {...props} />
                    <h2 className="center h5">{props.phoneText}</h2>
                    <a className={[contactSimpleStyles.link, "center"].join(' ')} href={`tel:${props.phoneNumber.split(' ').join('')}`} style={props.secondary ? {color: props.secondary} : null}>{props.phoneNumber}</a>
                    <p className="small center">{props.phoneTime}</p>
                  </div>
                : null }
                  
                {props.email ?
                  <div className={["col col-xs-12 col-md-4 col-lg-3", contactSimpleStyles.emailWrapper].join(' ')}>
                    <EmailIcon {...props} />
                    <h2 className="center h5">{props.emailText}</h2>
                    <a className={[contactSimpleStyles.link, "center"].join(' ')} href={`mailto:${props.email}`} style={props.secondary ? {color: props.secondary} : null}>{props.email}</a>
                  </div>
                : null }
                  
                </div>
            </div>
        </section> 
    )
}

export default ContactSimple