import React, { useState, useEffect } from "react"

import { StaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"


import Modal from "../../../components/hoc/Modal/Modal";
import Image from "gatsby-image";

import Classes from './AddSections.module.scss'

const AddSection = ({show, modalCloseHandler, saveSectionHandler, data, design }) => {
  

  const [choice, setChoice] = useState(null)

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.target.focus()
    }
  }

  useEffect(() => {
    
      setChoice(null)
  }, [show])

    const i18n = useIntl().formatMessage

    return (
        <Modal show={show} closeHandler={modalCloseHandler} wide >
        <h2 className="space-xs-up">{ i18n({ id: "general.sections"}) }</h2>
        <div className="flex between-xs space-xs-up">
              <button className="btn btn-danger" onClick={() => modalCloseHandler()}>{ i18n({ id: "general.cancel"}) }</button>
              { /* <button className="btn btn-secondary" onClick={() => saveSectionHandler(choice)}>{ i18n({ id: "general.insert"}) }</button> */}
            </div>
          <div className="row space-xs-up middle-xs">
            
            <div className="col col-xs-12 col-md-6 col-xl-4 space-xs-up text-center">
              <div className={[Classes.choice, 'mb-small', choice === "header" ? Classes.active : null].join(' ')} onClick={() => setChoice("header")} onKeyDown={handleKeyDown} role="button" tabIndex={0} >
                {design === "classic" ?
                  <Image fluid={data.header.childImageSharp.fluid} />
                  : design === "modern" ?
                  <Image fluid={data.headerModern.childImageSharp.fluid} />
                : null }
              </div>
              {choice !== "header" ?
                <p className="text-center space-xs-up">{i18n({ id: "sites_content.header.label"})}</p>
              : <button className={[Classes.button, "btn btn-secondary"].join(' ')} onClick={() => saveSectionHandler(choice)}>{ i18n({ id: "general.insert"}) }</button>
              }
            </div>
              <div className="col col-xs-12 col-md-6 col-xl-4 space-xs-up text-center">
                <div className={[Classes.choice, 'mb-small', choice === 'why_us' ? Classes.active : null].join(' ')} onClick={() => setChoice('why_us')} onKeyDown={handleKeyDown} role="button" tabIndex={0} >
                {design === "classic" ?
                  <Image fluid={data.values.childImageSharp.fluid} />
                  : design === "modern" ?
                  <Image fluid={data.valuesModern.childImageSharp.fluid} />
                : null }
                </div>
              {choice !== "why_us" ?
              <p className="text-center space-xs-up">{i18n({ id: "sites_content.why_us.label"})}</p>
              : <button className={[Classes.button, "btn btn-secondary"].join(' ')} onClick={() => saveSectionHandler(choice)}>{ i18n({ id: "general.insert"}) }</button>
              }
            </div>
            <div className="col col-xs-12 col-md-6 col-xl-4 space-xs-up text-center">
              <div className={[Classes.choice, 'mb-small', choice === "services" ? Classes.active : null].join(' ')} onClick={() => setChoice("services")} onKeyDown={handleKeyDown} role="button" tabIndex={0} >
              {design === "classic" ?
              <Image fluid={data.services.childImageSharp.fluid} />
              : design === "modern" ?
              <Image fluid={data.servicesModern.childImageSharp.fluid} />
            : null }
              </div>
              {choice !== "services" ?
              <p className="text-center space-xs-up">{i18n({ id: "sites_content.services.label"})}</p>
              : <button className={[Classes.button, "btn btn-secondary"].join(' ')} onClick={() => saveSectionHandler(choice)}>{ i18n({ id: "general.insert"}) }</button>
              }
            </div>
            <div className="col col-xs-12 col-md-6 col-xl-4 space-xs-up text-center">
              <div className={[Classes.choice, 'mb-small', choice === "text" ? Classes.active : null].join(' ')} onClick={() => setChoice("text")} onKeyDown={handleKeyDown} role="button" tabIndex={0} >
                <Image fluid={data.text.childImageSharp.fluid} />
              </div>
              {choice !== "text" ?
              <p className="text-center space-xs-up">{i18n({ id: "sites_content.text.label"})}</p>
              : <button className={[Classes.button, "btn btn-secondary"].join(' ')} onClick={() => saveSectionHandler(choice)}>{ i18n({ id: "general.insert"}) }</button>
              }
            </div>
            <div className="col col-xs-12 col-md-6 col-xl-4 space-xs-up text-center">
              <div className={[Classes.choice, 'mb-small', choice === "image_text_right" ? Classes.active : null].join(' ')} onClick={() => setChoice("image_text_right")} onKeyDown={handleKeyDown} role="button" tabIndex={0} >
              {design === "classic" ?
                <Image fluid={data.imageleft.childImageSharp.fluid} />
                : design === "modern" ?
                <Image fluid={data.imageleftModern.childImageSharp.fluid} />
              : null }
              </div>
              {choice !== "image_text_right" ?
              <p className="text-center space-xs-up">{i18n({ id: "sites_content.image_text_right.label"})}</p>
              : <button className={[Classes.button, "btn btn-secondary"].join(' ')} onClick={() => saveSectionHandler(choice)}>{ i18n({ id: "general.insert"}) }</button>
              }
            </div>
            <div className="col col-xs-12 col-md-6 col-xl-4 space-xs-up text-center">
              <div className={[Classes.choice, 'mb-small', choice === "image_text_left" ? Classes.active : null].join(' ')} onClick={() => setChoice("image_text_left")} onKeyDown={handleKeyDown} role="button" tabIndex={0} >
              {design === "classic" ?
                <Image fluid={data.imageright.childImageSharp.fluid} />
                : design === "modern" ?
                <Image fluid={data.imagerightModern.childImageSharp.fluid} />
              : null }
              </div>
              {choice !== "image_text_left" ?
              <p className="text-center space-xs-up">{i18n({ id: "sites_content.image_text_left.label"})}</p>
              : <button className={[Classes.button, "btn btn-secondary"].join(' ')} onClick={() => saveSectionHandler(choice)}>{ i18n({ id: "general.insert"}) }</button>
              }
            </div>
            <div className="col col-xs-12 col-md-6 col-xl-4 space-xs-up text-center">
              <div className={[Classes.choice, 'mb-small', choice === 'code' ? Classes.active : null].join(' ')} onClick={() => setChoice('code')} onKeyDown={handleKeyDown} role="button" tabIndex={0} >
                <Image fluid={data.iframe.childImageSharp.fluid} />
              </div>
              {choice !== "code" ?
              <p className="text-center space-xs-up">{i18n({ id: "sites_content.code.label"})}</p>
              : <button className={[Classes.button, "btn btn-secondary"].join(' ')} onClick={() => saveSectionHandler(choice)}>{ i18n({ id: "general.insert"}) }</button>
              }
            </div>
            <div className="col col-xs-12 col-md-6 col-xl-4 space-xs-up text-center">
              <div className={[Classes.choice, 'mb-small', choice === 'contact_section' ? Classes.active : null].join(' ')} onClick={() => setChoice("contact_section")} onKeyDown={handleKeyDown} role="button" tabIndex={0} >
              {design === "classic" ?
                <Image fluid={data.contact.childImageSharp.fluid} />
                : design === "modern" ?
                <Image fluid={data.contactModern.childImageSharp.fluid} />
              : null }
              </div>
              {choice !== "contact_section" ?
              <p className="text-center space-xs-up">{i18n({ id: "sites_content.contact_section.label"})}</p>
              : <button className={[Classes.button, "btn btn-secondary"].join(' ')} onClick={() => saveSectionHandler(choice)}>{ i18n({ id: "general.insert"}) }</button>
              }
            </div>
            <div className="col col-xs-12 col-md-6 col-xl-4 space-xs-up text-center">
              <div className={[Classes.choice, 'mb-small', choice === "contact_simple" ? Classes.active : null].join(' ')} onClick={() => setChoice("contact_simple")} onKeyDown={handleKeyDown} role="button" tabIndex={0} >
              {design === "classic" ?
              <Image fluid={data.contact_cta.childImageSharp.fluid} />
              : design === "modern" ?
              <Image fluid={data.contactCtaModern.childImageSharp.fluid} />
            : null }
              </div>
              {choice !== "contact_simple" ?
              <p className="text-center space-xs-up">{i18n({ id: "sites_content.contact_simple.label"})}</p>
              : <button className={[Classes.button, "btn btn-secondary"].join(' ')} onClick={() => saveSectionHandler(choice)}>{ i18n({ id: "general.insert"}) }</button>
              }
            </div>
          </div>
          
        <div className="flex between-xs">
          <button className="btn btn-danger" onClick={() => modalCloseHandler()}>{ i18n({ id: "general.cancel"}) }</button>
          {/* <button className="btn btn-secondary" onClick={() => saveSectionHandler(choice)}>{ i18n({ id: "general.insert"}) }</button> */}
        </div>
      </Modal>
    )
    }

export default props => (
    <StaticQuery
      query={graphql`
      query {
        header: file(relativePath: { eq: "sections/header_classic.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        headerModern: file(relativePath: { eq: "sections/header_modern.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        contact_cta: file(relativePath: { eq: "sections/contact_cta_classic.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        contactCtaModern: file(relativePath: { eq: "sections/contact_cta_modern.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        contact: file(relativePath: { eq: "sections/contact_classic.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        contactModern: file(relativePath: { eq: "sections/contact_modern.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        imageleft: file(relativePath: { eq: "sections/imageleft_classic.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        imageleftModern: file(relativePath: { eq: "sections/imageleft_modern.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        imageright: file(relativePath: { eq: "sections/imageright_classic.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        imagerightModern: file(relativePath: { eq: "sections/imageright_modern.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        iframe: file(relativePath: { eq: "sections/iframe_classic.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        services: file(relativePath: { eq: "sections/services_classic.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        servicesModern: file(relativePath: { eq: "sections/services_modern.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        values: file(relativePath: { eq: "sections/values_classic.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        valuesModern: file(relativePath: { eq: "sections/values_modern.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
        text: file(relativePath: { eq: "sections/text_classic.png" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              presentationWidth
              presentationHeight
            }
          }
        }
      }
      `}
      render={data => <AddSection data={data} {...props} />}
    />
  )