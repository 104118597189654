import React from 'react'
import headerStyles from './header.module.scss'
import {getContrastColor} from '../../hoc/helpers'
import ContactModal from '../../modern/contactModal/contactModal'
import ContactFormModern from '../contactModal/contactForm/contactForm'
import Img from "gatsby-image"
import { Link } from "gatsby"
import AnyLink from '../../hoc/anyLink/anyLink'


const Header = ({primary, secondary, lightColor, image, title, text, buttonText, showModal, altText, link}) => {
  
    return (
      <>
        <section className={headerStyles.headerWrapper} style={{backgroundColor: lightColor}}>
          <div className="container">
            <div className={["row end-lg", headerStyles.headerInner].join(' ')}>
                { title || text || buttonText ? 
                  <div className={["col col-md-12 col-lg-5", headerStyles.textWrapper].join(' ')}>
                    { title ? <h1>{title}</h1> : null }
                    { text ? <p>{text}</p> : null }
                    <AnyLink 
                      buttonText={buttonText}
                      link={link}
                      classes={headerStyles.button}
                      style={{ backgroundColor: secondary ? secondary : '#ff7b00', color: getContrastColor(secondary)}}
                      onClick={showModal}
                      />
                  </div>
                : null}
                <div className={["col col-md-12 col-lg-7 space-xs space-sm space-md", headerStyles.imageWrapper].join(' ')}>
                  {image && image.childImageSharp ?
                  <Img fluid={image.childImageSharp.fluid} loading="eager" alt={altText} className={headerStyles.image} />
                  :
                  <img src={image} className={headerStyles.image} alt={altText}></img>
                  }
              </div>
              </div>
            </div>
              {/* <svg className={headerStyles.svg} xmlns="http://www.w3.org/2000/svg"  preserveAspectRatio="xMidYMid slice" viewBox="0 0 1366 83.806">
                <path id="Path_37" data-name="Path 37" d="M0,120.135s152.273,44.122,386.3,44.122c116.077,0,304.514-63.973,542.105-63.973S1366,120.135,1366,120.135V80.451H0Z" transform="translate(0 -80.451)" fill={lightColor}/>
              </svg> */}
        </section>
      </>
        
    ) 
}

export default Header
