import React from "react"
import { Link } from "gatsby"
import NavbarStyles from './Navbar.module.scss'
import {getContrastColor} from '../../hoc/helpers'
import EmailIconMenu from '../../hoc/emailIconMenu'

const Navbar = ({ title, menuItems, phone, relativePos, navbarExpanded, setNavbarExpanded, secondary, primary, template, lightColor, disabled, showModal}) => {

    return (
        <>  
            

            <nav className={relativePos ? NavbarStyles.relativePos : null} style={{backgroundColor: lightColor}}>
                <div className="container">
                    <div className={["row", NavbarStyles.navbarInner].join(' ')}>

                        <div className={["col text-left", NavbarStyles.col].join(' ')}>
                            <Link className={[NavbarStyles.logo, disabled ? NavbarStyles.disabledLink : null].join(' ')} style={ secondary ? {color: secondary} : {color: '#ff7b00'} }  to="/">
                                {title}
                            </Link>
                        </div>
                        
                        <div className={["col col-xs-12", NavbarStyles.col, NavbarStyles.menuItems].join(' ')} style={{backgroundColor: lightColor}}>
                            <ul className={["list-unstyled flex end-xs", navbarExpanded ? NavbarStyles.visible : null].join(' ')}>
                                <li className={[NavbarStyles.navbarToggle, 'hidden-lg hidden-xl'].join(' ')}>
                                    <button onClick={!disabled ? () => setNavbarExpanded(!navbarExpanded) : null} style={ secondary ? {color: secondary} : {backgroundColor: '#ff7b00'} } aria-label="Toggle menu">
                                        <span className={NavbarStyles.toggleIcon} style={ secondary ? {backgroundColor: secondary} : {backgroundColor: '#0283f1'}}>
                                            <span>Toggle menu</span>
                                        </span>
                                    </button>
                                </li>

                                {menuItems.filter(item => item.slug !== '__home__').map((item, i) => 
                                    <li key={i}>
                                        <Link className={[NavbarStyles.pageLinks, disabled ? NavbarStyles.disabledLink : null].join(' ')}
                                            style={ secondary ? {color: secondary} : {color: '#ff7b00'} } 
                                            to={`${item.slug === '__home__' ? "/" : '/' + item.slug}`} 
                                            // to={'/'}
                                            activeClassName={NavbarStyles.active} 
                                            partiallyActive={false}>
                                            {item.name}
                                            {/* {item} */}
                                        </Link>
                                    </li>
                                )}

                                <li>
                                    <ul className={"list-unstyled"}>
                                        <li className={NavbarStyles.ctaWrapper}>
                                            <a style={ { border: `2px solid ${primary || 'black'}`, color: primary || 'black'}} className={[NavbarStyles.cta, NavbarStyles[template], disabled ? NavbarStyles.disabledLink : null].join(' ')} onClick={showModal}>
                                                <EmailIconMenu primary={primary} />
                                                <span className="sr-hidden">Email</span>
                                            </a>
                                        </li>
                                        <li className={NavbarStyles.ctaWrapper}>
                                            <a style={ { border: `2px solid ${primary || 'black'}`, backgroundColor: primary || '#ff7b00', color: getContrastColor(primary)}} className={[NavbarStyles.cta, NavbarStyles[template], disabled ? NavbarStyles.disabledLink : null].join(' ')} href={`tel:${phone}`}>{phone}</a>
                                        </li>
                                    </ul>
                                </li>

                            </ul>        
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar