import React from 'react'
// import emailStyles from './contactSimple.module.scss'

const EmailIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="29.887" height="25.432" viewBox="0 0 69.887 45.432">
            <g id="Group_72" data-name="Group 72" transform="translate(-1138.556 -817.606)">
                 <path id="Path_38" data-name="Path 38" d="M0,16.445,34.653,33.706,69.887,16.445V39.052a9.731,9.731,0,0,1-9.731,9.731H9.731A9.731,9.731,0,0,1,0,39.052Z" transform="translate(1138.556 814.255)" fill="white"/>
              <path id="Path_40" data-name="Path 40" d="M9.731,0H60.156c5.374,0,9.731,2.331,9.731,5.206V8.193L34.172,25.111,0,8.193V5.206C0,2.331,4.357,0,9.731,0Z" transform="translate(1138.556 817.606)" fill="white"/>
            </g>
        </svg> 

    )
}

export default EmailIcon
