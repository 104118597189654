import React, { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import cloneDeep from 'lodash.clonedeep'

import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {isLoggedIn, getData, getErrors, dataSavePending, dataSaved, deployPending, deployed, netlifySiteId, buildStatus, lastPublished, changesPublished } from '../../state/reducers/auth';
import saveAction from '../../state/async/authSaveData';
import createSiteAction from '../../state/async/netlifyCreateSite'
import pollAction from '../../state/async/netlifyPolling'
import deployAction from '../../state/async/netlifyDeployment'
import {getAccessToken} from '../../state/reducers/auth';

import SEO from "../../components/seo"

import dashboardStyles from './index.module.scss' 
import AddSections from "./AddSections/AddSectionsModal";
import SectionEditor from './SectionEditor/SectionEditor'
import Browser from './Browser/Browser'
import EditOverlay from './EditOverlay/EditOverlay'
// import DashboardJoyride from './ReactJoyride/dashboardJoyride'
import ReactJoyride from 'react-joyride'

// Sites components
import Header from '../../../../sites/src/components/classic/headerClassic/headerClassic'
import HeaderModern from '../../../../sites/src/components/modern/header/header'
import Services from '../../../../sites/src/components/classic/servicesList/servicesList'
import ServicesModern from '../../../../sites/src/components/modern/servicesList/servicesList'
import ImageRight from '../../../../sites/src/components/classic/textWithImageRight/textWithImageRight'
import ImageRightModern from '../../../../sites/src/components/modern/textWithImageRight/textWithImageRight'
import ImageLeft from '../../../../sites/src/components/classic/textWithImageLeft/textWithImageLeft'
import ImageLeftModern from '../../../../sites/src/components/modern/textWithImageLeft/textWithImageLeft'
import Contact from '../../../../sites/src/components/classic/contact/contact'
import ContactModern from '../../../../sites/src/components/modern/contact/contact'
import ContactSimple from '../../../../sites/src/components/classic/contactSimple/contactSimple'
import ContactSimpleModern from '../../../../sites/src/components/modern/contactSimple/contactSimple'
import Code from '../../../../sites/src/components/classic/code/code'
import CodeModern from '../../../../sites/src/components/modern/code/code'
import Footer from '../../../../sites/src/components/layout/footer/footer'
// import FooterModern from '../../../../sites/src/components/modern/footer/footer'
import WhyUs from "../../../../sites/src/components/classic/whyUs/whyUs";
import WhyUsModern from "../../../../sites/src/components/modern/whyUs/whyUs";
import TextModern from "../../../../sites/src/components/modern/text/text";
import Text from "../../../../sites/src/components/classic/text/text";
import Navbar from '../../../../sites/src/components/layout/Navbar/Navbar'

// import PageEditModal from "./PageEditModal/PageEditModal";


import SectionData from './sectionData' 
import tinycolor from 'tinycolor2'
import { number } from "prop-types";



const Dashboard = ({ access, data, saveAction, netlifySiteId, dataSavePending, dataSaved, deployPending, deployed, buildStatus, pollAction, lastPublished, deployAction, createSiteAction, changesPublished}) => {
  const i18n = useIntl().formatMessage
  const currentLang = useIntl().locale
  const profile = data.profile
  const website = profile.websites[0]
  const originalData = cloneDeep(website)

  const {page, ...websiteFlat} = website

  const contactEmail = website.contact_email
  const contactPhone = website.contact_phone


  const [showSections, setShowSections] = useState(false);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0)
  const [showSectionEdit, setShowSectionEdit] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [content, setContent] = useState(website)
  const [sections, setSections] = useState(content.page[0].section || [])
  const [pages, setPages] = useState(content.page)
  const [preview, setPreview] = useState(false)
  const [changesSaved, setChangesSaved] = useState(dataSaved)
  const [showPageEditModal, setShowPageEditModal] = useState(false)
  const [sectionData, setSectionData] = useState(SectionData(sections, content, currentSectionIndex, i18n))

  const lightColor = tinycolor.mix(website.secondary_color, '#ffffff', 92)
  const modern = website.chosen_template === 'modern'
  const classic = website.chosen_template === 'classic'

  const updatePage = (sections) => {
    let newPages = cloneDeep(pages)
    newPages[currentPage].section = sections
    setPages(newPages)
  }

  useEffect(() => {
    setSections(content.page[currentPage].section)
  }, [currentPage])


  useEffect(() => {
    // let newContent = cloneDeep(content)
    // // newContent.page = pages
    // newContent.page[currentPage].section = sections || []
    // // setContent(newContent)
    // setPages(newContent.page)
    setSectionData(SectionData(sections, content, currentSectionIndex, i18n))
  }, [sections])

  useEffect(() => {

    const newContent = cloneDeep(content)
    newContent.page = pages

    setContent(newContent)

    if(pages.length > currentPage) {
      setSections(newContent.page[currentPage].section || [])
    } else {
      setSections(newContent.page[currentPage - 1].section || [])
      setCurrentPage(currentPage -1)
    }

  }, [pages])  


  useEffect(() => {
    if(buildStatus === 'error') {
      saveAction(access.token, {changes_published: false}, null, 'PUT')
    } else if(buildStatus !== 'not_live') {
      pollAction(netlifySiteId, website.domain_name, currentLang, access.token)
    }
  }, [buildStatus])

  const pagesToFlatArr = (pg) => {
    return pg.map(p => { 
      return {
        name: p.name, 
        slug: p.slug,
        id: p.id
      }
    })
  }

  const removeSectionHandler = (i) => {
    const newArray = cloneDeep(sections)
    newArray.splice(i, 1)
    setSections(newArray)
    updatePage(newArray)
    setChangesSaved(false)

  }

  const moveSectionHandler = (i, direction) => {
    const toIndex = i === 0 && direction === -1 ? 0 : i === sections.length - 1 && direction === 1 ? i : i + direction
    let newArray = cloneDeep(sections)
    newArray[i].index = toIndex
    if(newArray[toIndex]) {
      newArray[toIndex].index = i
    }

    setSections(newArray)
    updatePage(newArray)
    setChangesSaved(false)

  }

  const addSectionHandler = (i, item) => {
    setCurrentSectionIndex(i)
    setShowSections(showSections => !showSections)
  }

  const saveEditSectionHandler = (submission) => {

    if(showSectionEdit !== 'footer') {
      let newArray = cloneDeep(sections)
      newArray[currentSectionIndex] = {...newArray[currentSectionIndex], ...submission}
      setSections(newArray)
      updatePage(newArray)
      // let newContent = cloneDeep(content)
      // // newContent.page = pages
      // newContent.page[currentPage].section = newArray || []
      
    } else {
      const newContent = {...content, ...submission}
      setContent(newContent)
    }
    setCurrentSectionIndex(0)
    setShowSectionEdit(null)
    setChangesSaved(false)
  }

  const saveSectionHandler = (section) => {
    const i = currentSectionIndex
    let newArray = cloneDeep(sections)

    const imageUrl = sectionData[section].datafields.image_url ? {image_url: sectionData[section].datafields.image_url, image: sectionData[section].datafields.image_url} : null

    newArray.splice( i, 0, {index: i, section_type: section, ...sectionData[section].datafields, ...imageUrl});

    setShowSections(showSections => !showSections)
    setSections(newArray)
    updatePage(newArray)
    setChangesSaved(false)

  }

  const editHandler = (type, i) => {
    setCurrentSectionIndex(i)
    setShowSectionEdit(type)
  }

  const editCloseHandler = (submission) => {
    setShowSectionEdit(null)
    setCurrentSectionIndex(0)
  }

  const modalCloseHandler = () => {
    setShowSections(showSections => !showSections)
  }

  const changePageHandler = (i) => {
    setCurrentPage(i)
  }

  const pageEditHandler = (action, i) => {
    const newPages = cloneDeep(pages)
    if(action === 'delete') {
      newPages.splice(i, 1);
    } else if(action === 'add') {
      setShowPageEditModal(true)
    } else if(action === 'edit') {
      setShowPageEditModal(i)
    }

    setPages(newPages)
    setChangesSaved(false)    
  }

  const savePageHandler = async (submission) => {
    
    const index = showPageEditModal
    const newPage = submission
    let newPages = cloneDeep(pages)
    console.log('saving page', index)
    if(typeof index !== 'number') {
      console.log('creating new page', submission)
      newPage.index = pages.length + 1
      newPage.section = []
      newPages.push(newPage)
      console.log('creating new page', submission, newPages)
    } else {
      newPages[index] = {...newPages[index], ...submission}
      // newPages[index].name = submission.name
      // newPages[index].slug = submission.slug
    }
        
    await setPages(newPages)
    setShowPageEditModal(false)
    setChangesSaved(false)

    if(!index && index !== 0) {
      await setCurrentPage(newPages.length - 1)
    }
  }

  const pageEditCloseHandler = () => {
    setShowPageEditModal(false)
  }
  
  const cancelClickHandler = async () => {
    
    await setPages(originalData.page)
    if(dataSaved) {
      setChangesSaved(true)
    }
  }

  const saveSiteClickHandler = async () => {

    const clean = (object) => {
      Object
          .entries(object)
          .forEach(([k, v]) => {
              if (v && typeof v === 'object') {
                  clean(v);
              }
              if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
                  if (Array.isArray(object)) {
                      object.splice(k, 1);
                  } else {
                      delete object[k];
                  }
              }
          });
      return object;
  }

    const nameIdByOrder = (arr) => {
      const newArr = arr ? arr.map((item, i) => 
        item = {...item, index: i + 1}
      ) : []


      return newArr

    }

    const orderSections = (pages) => {
      

      const newPages = pages.map((page, i) => 
        page = {...page, section: nameIdByOrder(page.section)}
      )

      return newPages
    }
    if(profile.active) {
      let cleanedData = cloneDeep(content)
      cleanedData = await clean(cleanedData)
      let pages = cleanedData.page

      pages = await nameIdByOrder(cleanedData.page)
      pages = await orderSections(pages)

      cleanedData.page = pages
      console.log('DATA TO SAVE:::::: ', cleanedData)
      console.log('DATA BEFORE SAVE:::::', content)
      await saveAction(access.token, cleanedData, netlifySiteId || website.netlify_side_id, 'PUT', null, null, false)
      if(dataSaved) {
        setChangesSaved(true)
      }
    }
  }

  const previewClickHandler = () => {
    setPreview(!preview)
  }

  const publishClickHandler = async () => {
    if(profile.active) {

      if(!website.netlify_site_live) {
        createSiteAction(website.site_id, website.domain_name, currentLang, access.token)
      } else {
        deployAction(netlifySiteId, website.domain_name, currentLang, access.token, buildStatus, websiteFlat, website.site_id)
      }
    }
  }

  let sectionEditor = null

  if(showSectionEdit) {   

    const editedSectionData = SectionData(sections, content, currentSectionIndex, i18n)
    sectionEditor = (<SectionEditor 
                      editCloseHandler={editCloseHandler} 
                      saveSection={saveEditSectionHandler} 
                      title={i18n({ id: `sites_content.${editedSectionData[showSectionEdit].name}.label` })} 
                      description={editedSectionData[showSectionEdit].description} 
                      show={showSectionEdit}  
                      formElements={showSectionEdit ? editedSectionData[showSectionEdit].formFields : null} 
                      industry={website.industry}
                      pages={pagesToFlatArr(pages)}
                      />)
  }

  // React JoyRide
  let [runState, setRunState] = useState(false)

  if (!localStorage.noFirstVisit) {
    setRunState(true);
    localStorage.noFirstVisit = "1";
  }
  
  const dashboardJoyrideHandler = (event) => {
    event.preventDefault();
    setRunState(true);
  }
  
  const [dashboardJoyrideSteps] = useState({
        locale: {
          next: i18n({ id: "pages.dashboard.joyride.toolbox.next" }),
          back: i18n({ id: "pages.dashboard.joyride.toolbox.back" }),
          skip: i18n({ id: "pages.dashboard.joyride.toolbox.skip" }),
          last: i18n({ id: "pages.dashboard.joyride.toolbox.last" }),
        },
        steps: [
          {
            target: ".tourStart",
            title: i18n({ id: "pages.dashboard.joyride.step_one.title" }),
            content: i18n({ id: "pages.dashboard.joyride.step_one.content" }),
            placement: "center",
            isFixed: true,
            spotlightPadding: '5',
            disableBeacon: true,
            
          },
          {
            target: ".browser",
            title: i18n({ id: "pages.dashboard.joyride.step_two.title" }),
            content: i18n({ id: "pages.dashboard.joyride.step_two.content" }),
            spotlightPadding: '0',
            placement: 'auto'
          },
          {
            target: ".header",
            title: i18n({ id: "pages.dashboard.joyride.step_tree.title" }),
            content: i18n({ id: "pages.dashboard.joyride.step_tree.content" }),
            spotlightPadding: '0',
            placement: 'auto',
          },
          {
            target: ".addSection",
            title: i18n({ id: "pages.dashboard.joyride.step_four.title" }),
            content: i18n({ id: "pages.dashboard.joyride.step_four.content" }),
            spotlightPadding: '5',
            placement: "top",
          },
          {
            target: ".pages",
            title: i18n({ id: "pages.dashboard.joyride.step_five.title" }),
            content: i18n({ id: "pages.dashboard.joyride.step_five.content" }),
            spotlightPadding: '5',
            placement: "top",
          },
          {
            target: ".save",
            title: i18n({ id: "pages.dashboard.joyride.step_six.title" }),
            content: i18n({ id: "pages.dashboard.joyride.step_six.content" }),
            spotlightPadding: '5',
            placement: "top-start",
          },
          {
            target: ".publish",
            title: i18n({ id: "pages.dashboard.joyride.step_seven.title" }),
            content: i18n({ id: "pages.dashboard.joyride.step_seven.content" }),
            spotlightPadding: '5',
          },
      ]
    })

  return (
    <>
    <ReactJoyride
      locale={dashboardJoyrideSteps.locale}
      steps={dashboardJoyrideSteps.steps}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      run={runState}
      scrollDuration={800}
      disableOverlayClose={true}
      callback={({status, type}) => status === 'finished' || type === 'beacon' ? setRunState(false) : null }
      styles={{
        options: {
          overlayColor: 'rgba(0, 0, 0, 0.6)',
          primaryColor: '#29CEA2',
          borderRadius: '1rem',
        },
        tooltip: {
          borderRadius: '1rem',
          fontSize: '1.1rem',
          paddingTop: '3rem',
        },
        tooltipTitle: {
          fontSize: '1.2rem',
        },
        buttonNext: {
          borderRadius: '5rem',
          padding: '0.7em 1em'
        }
      }}
    />
      <SEO title="Dashboard"/>
      <section className={["section-padding  tourStart", dashboardStyles.headerOuter].join(' ')}>
        <div className="container">
          <div className={["row space-xs-up-big", dashboardStyles.header].join(' ')}>
            <div className="col col-xs-12 col-md-5 col-md-offset-1 text-center-xs text-left-md">
              <h1>{ content ? content.company_name : null }</h1>
              <a href={`https://${website.domain_name}.worksiter.${profile.country === 'da' ? 'dk' : profile.country}`} target="_blank" rel="noopener noreferrer">
                {`www.${website.domain_name}.worksiter.${profile.country === 'da' ? 'dk' : profile.country}`}
              </a>
              <p className={[
                dashboardStyles.tag, 
                !profile.active ? dashboardStyles.tagRed : 
                ((buildStatus === 'building' || buildStatus === 'new' || buildStatus === 'enqueued' || buildStatus === 'uploading' || buildStatus === 'not_retrieved' || buildStatus === 'retrieving' || buildStatus === 'processing') ? 
                  dashboardStyles.tagYellow 
                : buildStatus === 'ready' || buildStatus === 'current' ? 
                  dashboardStyles.tagGreen 
                : buildStatus === 'error' ? 
                  dashboardStyles.tagRed 
                : dashboardStyles.tagRed),
                buildStatus === 'building' || buildStatus === 'new' || buildStatus === 'enqueued' || buildStatus === 'uploading' || buildStatus === 'processing' ? dashboardStyles.pulsing : null,
                "small text-bold"
              ].join(' ')}>
                { profile.active && buildStatus ? i18n({ id: `pages.dashboard.status.${buildStatus}.short` }) : profile.active ? '...' : null}
                {!profile.active && i18n({ id: `pages.dashboard.status.not_active.short` })}
              </p>
              {profile.active ?
              <p className="no-mb mt-small">
                {buildStatus ? i18n({ id: `pages.dashboard.status.${buildStatus}.long` }) : null}
              </p>
              :
              <p className="mt-small text-red">{i18n({ id: `pages.dashboard.status.not_active.long` })}</p>
              }
              {profile.active ?
              <p className="small">
                {lastPublished && buildStatus ? 
                  `${i18n({ id: `pages.dashboard.header.last_updated` })} ${lastPublished}` : 
                buildStatus === 'not_retrieved' || buildStatus === 'retrieving' ? 
                  null :
                  null
                }
              </p>
              :
              null
              }
              <button 
              className="btn btn-large btn-secondary space-xs-up publish" 
              onClick={() => publishClickHandler()} 
              disabled={buildStatus === 'building' || buildStatus === 'processing' || buildStatus === 'enqueued' || buildStatus === 'uploading' || !changesSaved || !profile.active}>
                {i18n({ id: "pages.dashboard.header.publish" })}
                {buildStatus === 'building' || buildStatus === 'processing' || buildStatus === 'enqueued' || buildStatus === 'uploading' || !changesSaved ? 
                <span className="disabledText">
                  {buildStatus === 'building' || buildStatus === 'processing' || buildStatus === 'enqueued' || buildStatus === 'uploading' ?
                  i18n({ id: "pages.dashboard.header.publish_disabled_building" })
                  : !changesSaved ?
                  i18n({ id: "pages.dashboard.header.publish_disabled_not_saved" })
                  : null }
                </span> 
                : null }
              </button>
            </div>
          </div>
          <Browser 
            dashboardJoyrideHandler={dashboardJoyrideHandler} 
            i18n={i18n} 
            data={data} 
            content={content} 
            website={website} 
            profile={profile} 
            currentPage={currentPage} 
            changePageHandler={changePageHandler} 
            pageEditHandler={pageEditHandler} 
            previewClickHandler={previewClickHandler} 
            preview={preview} 
            saveSiteClickHandler={saveSiteClickHandler} 
            cancelClickHandler={cancelClickHandler} 
            changesSaved={changesSaved} 
            dataSavePending={dataSavePending}
            changesPublished={changesPublished}
            classes="sections-builder"
            >
            {preview ? <Navbar disabled title={website.company_name} menuItems={pages.filter((page) => {return page.slug !== '__home__'})} phone={contactPhone} relativePos primary={content.primary_color} secondary={content.secondary_color} template={website.chosen_template} lightColor={lightColor}/> : null }
            {!preview ?
            <button 
              className={[dashboardStyles.btnAdd, 'btn btn-unstyled text-blue addSection'].join(' ')} 
              onClick={() => addSectionHandler(0)}>
              { i18n({ id: "pages.dashboard.browser.add_content" }) } +
            </button>
            : null }

              {sections.length > 0 ? 
               <TransitionGroup className="todo-list" component="div">
              {sections.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0)).map((s, i) => (
              <CSSTransition key={i} timeout={500} classNames="item">
                <div className="section-item">
                  <EditOverlay 
                    editHandler={() => editHandler(s.section_type, i)} 
                    removeSectionHandler={removeSectionHandler} 
                    moveSectionHandler={moveSectionHandler} 
                    sectionType={s.section_type}
                    i={i}
                    preview={preview}>
                   
                      {/* <ContactForm/> */}
                      {/* <h2 className="text-center">{s.title} : {i}</h2> */}
                    {s.section_type === 'header' && classic ? 
                      <Header title={s.title} text={s.subtitle} image={s.image} buttonText={s.link_text} primary={content.primary_color} secondary={content.secondary_color} />
                    : s.section_type === 'header' && modern ? 
                      <HeaderModern title={s.title} text={s.subtitle} image={s.image} buttonText={s.link_text} primary={content.primary_color} secondary={content.secondary_color} lightColor={lightColor} />
                    : s.section_type === 'services' && classic ?
                      <Services title={s.title} services={s.services.split(", ")} primary={content.primary_color} secondary={content.secondary_color} lightColor={lightColor}/>
                    : s.section_type === 'services' && modern ?
                      <ServicesModern title={s.title} services={s.services.split(", ")} primary={content.primary_color} secondary={content.secondary_color} lightColor={lightColor}/>
                    : s.section_type === 'image_text_right' && classic ?
                      <ImageRight textTitle={s.title} text={s.subtitle} image={s.image} buttonText={s.button_text}/>
                    : s.section_type === 'image_text_right' && modern ?
                      <ImageRightModern textTitle={s.title} text={s.subtitle} image={s.image} buttonText={s.button_text}/>
                    : s.section_type === 'image_text_left' && classic ?
                      <ImageLeft textTitle={s.title} text={s.subtitle} image={s.image} buttonText={s.button_text}/>
                    : s.section_type === 'image_text_left' && modern ?
                      <ImageLeftModern textTitle={s.title} text={s.subtitle} image={s.image} buttonText={s.button_text}/>
                    : s.section_type === 'contact_section' && classic ?
                      <Contact title={s.title} buttonText={s.button_text} text={s.text} phone={s.phone || contactPhone} primary={content.primary_color} secondary={content.secondary_color} lightColor={lightColor}/>
                    : s.section_type === 'contact_section' && modern ?
                      <ContactModern title={s.title} buttonText={s.button_text} text={s.text} phone={s.phone || contactPhone} primary={content.primary_color} secondary={content.secondary_color} lightColor={lightColor}/>
                    : s.section_type === 'contact_simple' && classic ?
                      <ContactSimple phoneText={s.phone_text} phoneNumber={s.phone || contactPhone} phoneTime={s.phone_time} emailText={s.email_text} email={s.email || contactEmail} primary={content.primary_color} secondary={content.secondary_color} lightColor={lightColor}/>
                    : s.section_type === 'contact_simple' && modern ?
                      <ContactSimpleModern phoneText={s.phone_text} phoneNumber={s.phone || contactPhone} phoneTime={s.phone_time} emailText={s.email_text} email={s.email || contactEmail} primary={content.primary_color} secondary={content.secondary_color} buttonText={"fdfds"} sendButtonText={"fd"} lightColor={lightColor}/>
                    // : s.section_type === 'footer' && classic ?
                    //   <Footer titleOne={s.title_one} textOne={s.text_one} titleTwo={s.title_two} textTwo={s.text_two} titleThree={s.title_three} textThree={s.text_three} />
                    // : s.section_type === 'footer' && modern ?
                    //   <FooterModern titleOne={s.title_one} textOne={s.text_one} titleTwo={s.title_two} textTwo={s.text_two} titleThree={s.title_three} textThree={s.text_three} lightColor={lightColor}/>
                    : s.section_type === 'why_us' && classic ?
                    <div className="header">
                      <WhyUs title={s.title} text={s.text} textBlocks={[s.text_one ? {title: s.title_one, text: s.text_one} : null, s.text_two ? {title: s.title_two, text: s.text_two} : null, s.text_three ? {title: s.title_three, text: s.text_three} : null]} primary={content.primary_color} secondary={content.secondary_color} lightColor={lightColor}/>
                      </div>
                    : s.section_type === 'why_us' && modern ?
                    <div className="header">
                      <WhyUsModern title={s.title} text={s.text} textBlocks={[s.text_one ? {title: s.title_one, text: s.text_one} : null, s.text_two ? {title: s.title_two, text: s.text_two} : null, s.text_three ? {title: s.title_three, text: s.text_three} : null]} primary={content.primary_color} secondary={content.secondary_color} lightColor={lightColor}/>
                      </div>
                    : s.section_type === 'code' && classic ?
                      <Code title={s.title} text={s.text} embed={s.embed} />
                    : s.section_type === 'code' && modern ?
                      <CodeModern title={s.title} text={s.text} embed={s.embed} />
                    : s.section_type === 'text' && classic ?
                      <Text title={s.title} text={s.text} embed={s.embed} />
                    : s.section_type === 'text' && modern ?
                      <TextModern title={s.title} text={s.text} embed={s.embed} />
                    : null}
                    
                  </EditOverlay>

                  {!preview ?
                  <button 
                    className={[dashboardStyles.btnAdd, 'btn btn-unstyled text-blue'].join(' ')} 
                    onClick={() => addSectionHandler(i + 1)}>
                      { i18n({ id: "pages.dashboard.browser.add_content" }) } +
                  </button>
                  : null }

                </div>
              </CSSTransition>
              ))}
              </TransitionGroup>

              : sections.length === 0 ?
              <div className={[dashboardStyles.emtpyPage].join(' ')}>
                <h3>{i18n({ id: "pages.dashboard.browser.add_some_sections" }) }</h3>
              </div>
              : null }
              <EditOverlay 
                    editHandler={() => editHandler('footer')} 
                    sectionType={'footer'}
                    notMoveable
                    notDeleteable
                    preview={preview}
                    style={{borderBottomRightRadius: '1rem', borderBottomLeftRadius: '1rem'}}
                    >
                <Footer 
                  titleOne={content.footer_title_one} 
                  textOne={content.footer_text_one} 
                  titleTwo={content.footer_title_two} 
                  textTwo={content.footer_text_two} 
                  titleThree={content.footer_title_three} 
                  textThree={content.footer_text_three} 
                  lightColor={classic && lightColor} 
                   />

              </EditOverlay>
          </Browser>
        </div>
      </section>
      <AddSections modalCloseHandler={modalCloseHandler} saveSectionHandler={saveSectionHandler} show={showSections} design={website.chosen_template}/>
        <SectionEditor 
          pages={pagesToFlatArr(pages)}
          editCloseHandler={pageEditCloseHandler} 
          saveSection={savePageHandler} 
          title={Number.isInteger(showPageEditModal) ? i18n({ id: "pages.dashboard.browser.edit_page" }) : i18n({ id: "pages.dashboard.browser.add_new_page" }) }
          show={showPageEditModal !== false} 
          formElements={
            [
              { type: 'text', name: 'name', value: pages[showPageEditModal] ? pages[showPageEditModal].name : '', label: i18n({ id: "general.name" }), required: true },
              { type: 'text', name: 'seo_description', value: pages[showPageEditModal] ? pages[showPageEditModal].seo_description : '', label: i18n({ id: "general.seo_description" }), required: false },
              { type: 'image', name: 'image', value: pages[showPageEditModal] ? pages[showPageEditModal].image : null, label: i18n({ id: "general.seo_image" }), required: false },
          ]} 
          industry={website.industry}
          />
      {sectionEditor}
    </>
    )
}

const mapStateToProps = state => ({
  error: getErrors(state.auth),
  data: getData(state.auth),
  access: getAccessToken(state.auth),
  isLoggedIn: isLoggedIn(state.auth),
  dataSavePending: dataSavePending(state.auth),
  dataSaved: dataSaved(state.auth),
  deployPending: deployPending(state.auth), 
  deployed: deployed(state.auth),
  netlifySiteId: netlifySiteId(state.auth),
  buildStatus: buildStatus(state.auth),
  lastPublished: lastPublished(state.auth),
  changesPublished: changesPublished(state.auth),
})

const mapDispatchToProps = dispatch => bindActionCreators({
  saveAction: saveAction,
  pollAction: pollAction,
  deployAction: deployAction,
  createSiteAction: createSiteAction
}, dispatch)


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
