import React from 'react'
import Classes from './footer.module.scss'

const Footer = (props) => {
    
    return (
        <footer className={[Classes.sectionPadding, Classes.footer].join(' ')} style={props.lightColor ? {backgroundColor: props.lightColor} : null}>
            <div className="container">
                <div className="row text-center">
                        {props.textOne ?
                        <div className="col col-xs-12 col-md-6 col-lg-4 space-xs-up">
                            <h2 className="h5">{props.titleOne}</h2>
                            <p>{props.textOne}</p>
                        </div>
                        : null}
                        {props.textTwo ?
                        <div className="col col-xs-12 col-md-6 col-lg-4 space-xs-up">
                            <h2 className="h5">{props.titleTwo}</h2>
                            <p>{props.textTwo}</p>
                        </div>
                        : null}
                        {props.textThree ?
                        <div className="col col-xs-12 col-md-6 col-lg-4 space-xs-up">
                            <h2 className="h5">{props.titleThree}</h2>
                            <p>{props.textThree}</p>
                        </div>
                        : null}
                    <div className="col col-xs-12 text-center small">
                        <p>Lavet med <a className={Classes.watermark} target="_blank" href="https://worksiter.com" style={props.secondary ? {color: props.secondary} : null} rel="noopener noreferrer">Worksiter.com</a></p>
                    </div>
                </div>
                
            </div>
        </footer>
        
    )
}

export default Footer

