import React from 'react'
import OverlayStyles from './EditOverlay.module.scss'
import { useIntl } from "gatsby-plugin-intl"

const EditOverlay = ({editHandler, removeSectionHandler, moveSectionHandler, i, sectionType, preview, notDeleteable, notMoveable, children, style}) => {

    const i18n = useIntl().formatMessage

    return (
        <div className={[OverlayStyles.siteSection, preview ? OverlayStyles.preview : null].join(' ')} style={style}>
            {children}
            <div className={OverlayStyles.overlay}>
                <h5 className={OverlayStyles.siteSectionTitle}>{i18n({ id: `sites_content.${sectionType}.label`})}</h5>
                <button className={OverlayStyles.edit} onClick={() => editHandler()}>
                    <span className="hidden">Edit</span>
                </button>
                {!notDeleteable &&                
                <button className={OverlayStyles.delete} onClick={() => removeSectionHandler(i)}>
                    <span className="hidden">Delete</span>
                </button>
                }
                {!notMoveable &&
                <div className={OverlayStyles.move}>
                    <button className={OverlayStyles.up} onClick={() => moveSectionHandler(i, -1)}>
                    <span className="hidden">Move section up</span>
                    </button>
                    <button className={OverlayStyles.down} onClick={() => moveSectionHandler(i, 1)}>
                    <span className="hidden">Move section down</span>
                    </button>
                </div>
                }
            </div>
        </div>
    )
}

export default EditOverlay