import React from 'react'
import contactStyles from './contact.module.scss'

const PhoneIcon = ({secondary}) => {
    return (
            <svg xmlns="http://www.w3.org/2000/svg" width="46" height="80" viewBox="0 0 46 80" className={contactStyles.phoneIcon}>
                <g id="Group_85" data-name="Group 85" transform="translate(-725 -808)">
                    <g id="Rectangle_64" data-name="Rectangle 64" transform="translate(725 808)" fill="none" style={secondary ? {stroke: secondary} : {stroke: '#0283f1'} } strokeWidth="5">
                    <rect width="46" height="80" rx="11" stroke="none"/>
                    <rect x="2.5" y="2.5" width="41" height="75" rx="8.5" fill="none"/>
                    </g>
                    <path id="Rectangle_65" data-name="Rectangle 65" d="M0,0H22a0,0,0,0,1,0,0V1a4,4,0,0,1-4,4H4A4,4,0,0,1,0,1V0A0,0,0,0,1,0,0Z" transform="translate(737 812)" style={secondary ? {fill: secondary} : {fill: '#0283f1'} } />
                    <g id="Rectangle_66" data-name="Rectangle 66" transform="translate(736 847)" fill="none" style={secondary ? {stroke: secondary} : {stroke: '#0283f1'} } strokeWidth="1">
                    <rect width="7" height="7" rx="2" stroke="none"/>
                    <rect x="0.5" y="0.5" width="6" height="6" rx="1.5" fill="none"/>
                    </g>
                    <g id="Rectangle_71" data-name="Rectangle 71" transform="translate(736 857)" fill="none" style={ secondary ? {stroke: secondary} : {stroke: '#0283f1'} } strokeWidth="1">
                    <rect width="7" height="7" rx="2" stroke="none"/>
                    <rect x="0.5" y="0.5" width="6" height="6" rx="1.5" fill="none"/>
                    </g>
                    <g id="Rectangle_74" data-name="Rectangle 74" transform="translate(736 867)" fill="none" style={secondary ? {stroke: secondary} : {stroke: '#0283f1'} } strokeWidth="1">
                    <rect width="7" height="7" rx="2" stroke="none"/>
                    <rect x="0.5" y="0.5" width="6" height="6" rx="1.5" fill="none"/>
                    </g>
                    <g id="Rectangle_67" data-name="Rectangle 67" transform="translate(745 847)" fill="none" style={secondary ? {stroke: secondary} : {stroke: '#0283f1'} } strokeWidth="1">
                    <rect width="7" height="7" rx="2" stroke="none"/>
                    <rect x="0.5" y="0.5" width="6" height="6" rx="1.5" fill="none"/>
                    </g>
                    <g id="Rectangle_70" data-name="Rectangle 70" transform="translate(745 857)" fill="none" style={secondary ? {stroke: secondary} : {stroke: '#0283f1'} } strokeWidth="1">
                    <rect width="7" height="7" rx="2" stroke="none"/>
                    <rect x="0.5" y="0.5" width="6" height="6" rx="1.5" fill="none"/>
                    </g>
                    <g id="Rectangle_73" data-name="Rectangle 73" transform="translate(745 867)" fill="none" style={secondary ? {stroke: secondary} : {stroke: '#0283f1'} } strokeWidth="1">
                    <rect width="7" height="7" rx="2" stroke="none"/>
                    <rect x="0.5" y="0.5" width="6" height="6" rx="1.5" fill="none"/>
                    </g>
                    <g id="Rectangle_68" data-name="Rectangle 68" transform="translate(754 847)" fill="none" style={secondary ? {stroke: secondary} : {stroke: '#0283f1'} } strokeWidth="1">
                    <rect width="7" height="7" rx="2" stroke="none"/>
                    <rect x="0.5" y="0.5" width="6" height="6" rx="1.5" fill="none"/>
                    </g>
                    <g id="Rectangle_69" data-name="Rectangle 69" transform="translate(754 857)" fill="none" style={secondary ? {stroke: secondary} : {stroke: '#0283f1'} } strokeWidth="1">
                    <rect width="7" height="7" rx="2" stroke="none"/>
                    <rect x="0.5" y="0.5" width="6" height="6" rx="1.5" fill="none"/>
                    </g>
                    <g id="Rectangle_72" data-name="Rectangle 72" transform="translate(754 867)" fill="none" style={secondary ? {stroke: secondary} : {stroke: '#0283f1'} } strokeWidth="1">
                    <rect width="7" height="7" rx="2" stroke="none"/>
                    <rect x="0.5" y="0.5" width="6" height="6" rx="1.5" fill="none"/>
                    </g>
                </g>
            </svg>
    )
}

export default PhoneIcon
