import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import Form from '../components/hoc/Form/Form'

import {getAccessToken, isLoggedIn, getData, getErrors, dataSavePending, dataSaveSuccess} from '../state/reducers/auth';
import saveAction from '../state/async/authSaveData';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const Settings = ({saveAction, error, data, access, isLoggedIn, dataSavePending, dataSaveSuccess}) => {

    const dataQuery = useStaticQuery(graphql`
    query {
        modernDesign: file(relativePath: { eq: "modern_design.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    presentationWidth
                    presentationHeight
                }
            }
        }
        classicDesign: file(relativePath: { eq: "classic_design.png" }) {
            childImageSharp {
                fluid(maxWidth: 800, quality: 80) {
                    base64
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    presentationWidth
                    presentationHeight
                }
            }
        }
    }
  `)

    const modernDesign = dataQuery.modernDesign.childImageSharp
    const classicDesign = dataQuery.classicDesign.childImageSharp

    const website = data.profile.websites[0]

    const i18n = useIntl().formatMessage
    const [submission, setSubmission] = useState()
    const [activeTab, setActiveTab] = useState(0)
    const currentLang = useIntl().locale

    const saveSettingsHandler = (sub) => {
        console.log('SUBMISSION: ', sub)
        const updatedomain = sub.domain_name !== website.domain_name
        let domainEnding
        if(updatedomain) {
            domainEnding = currentLang === 'da' ? 'dk' : 'com'
        } else {}
        console.log('SHOULD UPDATE DOMAIN NAME?', updatedomain)
        setSubmission(sub)
        saveAction(access.token, sub, data.profile.websites[0].netlify_site_id, 'PUT', null, updatedomain, null, domainEnding)
    }

    const infoForm = activeTab === 0 ? <Form 
        submitHandler={saveSettingsHandler}
        submitText={ i18n({ id: "general.save" }) }
        loading={dataSavePending}
        // success={dataSaveSuccess}
        formElements={[
            { type: 'text', name: 'company_name', label: i18n({ id: "pages.register.company_name" }), required: true, value: submission && submission.company_name ? submission.company_name : website.company_name, help: i18n({ id: "pages.register.company_description" }) },
            { type: 'select', name: 'industry', value: submission && submission.industry ? submission.industry : website.industry, label: i18n({ id: "pages.register.industry" }), required: true, options: [
                {value: 'electrician', label: i18n({ id: "pages.register.industries.electrician" }) },
                {value: 'plumber', label: i18n({ id: "pages.register.industries.plumber" }) },
                {value: 'carpenter', label: i18n({ id: "pages.register.industries.carpenter" }) },
                {value: 'other', label: i18n({ id: "pages.register.industries.other" }) }
            ], help: i18n({ id: "pages.register.industry_description" }) },
            { type: 'text', name: 'domain_name', value: submission && submission.domain_name ? submission.domain_name : website.domain_name, label: i18n({ id: "pages.register.wanted_domain" }), required: false, help: i18n({ id: "pages.register.wanted_domain_description" }) },
            { type: 'email', name: 'contact_email', value: submission && submission.contact_email ? submission.contact_email : website.contact_email, label: i18n({ id: "pages.register.contact_email" }), required: false, help: i18n({ id: "pages.register.contact_email_description" }) },
            { type: 'text', name: 'contact_phone', value: submission && submission.contact_phone ? submission.contact_phone : website.contact_phone, label: i18n({ id: "pages.register.contact_phone" }), required: false, help: i18n({ id: "pages.register.contact_phone_description" }) }
        ]}
        cancelBtn={false}
    /> : null

    const designForm = activeTab === 1 ? <Form 
        submitHandler={saveSettingsHandler}
        submitText={ i18n({ id: "general.save" }) }
        loading={dataSavePending}
        // success={dataSaveSuccess}
        formElements={[
            { type: 'select_image', name: 'chosen_template', value: submission && submission.chosen_template ? submission.chosen_template : website.chosen_template, label: i18n({ id: "pages.register.design_template" }), required: true, options: [
                {value: 'classic', label: i18n({ id: "pages.register.classic_design" }), image: classicDesign },
                {value: 'modern', label: i18n({ id: "pages.register.modern_design" }), image: modernDesign }
            ], help: i18n({ id: "pages.register.industry_description" }) },
            { type: 'color_theme', name: 'color_theme', label: i18n({ id: "pages.register.color" }), help: i18n({ id: "pages.register.can_change_later" }), required: true, customColor: {help: i18n({ id: "pages.register.own_colors" }), primary: i18n({ id: "pages.register.color_name_one" }), secondary: i18n({ id: "pages.register.color_name_two" })}, primaryValue: website.primary_color || '#1267C9', secondaryValue: website.secondary_color || '#C7C7C7', options: [
                {primary: '#ff7b00', secondary: "#0283f1"},
                {primary: '#29CEA2', secondary: "#3e6289"},
                {primary: '#db2e22', secondary: "#508faf"},  
            ]}
        ]}
        cancelBtn={false}
    /> : null

    return (
    <>
        <SEO title="Settings" />
        <section className="section-padding-big">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12 col-lg-4 col-xl-3 text-left">
                        <ul className="list-inline list-unstyled list-tabs list-vertical card space-xs-up">
                            <li className={[activeTab === 0 ? 'active-tab' : null, ''].join(' ')} onClick={() => setActiveTab(0)}>Info</li>
                            <li className={[activeTab === 1 ? 'active-tab' : null, ''].join(' ')} onClick={() => setActiveTab(1)}>Design</li>
                        </ul>
                    </div>
                    <div className="col col-xs-12 col-lg-8 col-xl-6 text-left">                    
                        <div className="card space-xs-up">
                            {infoForm}
                            {designForm}
                            <div className="space-xs-up"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
    )
}

// export default Pricing
const mapStateToProps = state => ({
    error: getErrors(state.auth),
    data: getData(state.auth),
    access: getAccessToken(state.auth),
    isLoggedIn: isLoggedIn(state.auth),
    dataSavePending: dataSavePending(state.auth),
    dataSaveSuccess: dataSaveSuccess(state.auth),
})

const mapDispatchToProps = dispatch => bindActionCreators({
    saveAction: saveAction
  }, dispatch)
  
  
export default connect(mapStateToProps, mapDispatchToProps)(Settings);

