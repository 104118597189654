import React from 'react'
import contactStyles from './contact.module.scss'
import PhoneIcon from './phoneIcon'
import {getContrastColor} from '../../hoc/helpers'
import EmailIcon from '../../hoc/emailIcon'


const ContactModern = ({lightColor, setFullname, setPhone, setEmail, setMessage, primary, secondary, title, text, phone, submitAssignmentHandler, sendButtonText}) => {

    return (
        <section className={["section"].join(' ')} style={{backgroundColor: lightColor}}>
            <div className="container">
                <div className="row flex middle-xs text-left">
                    <div className="col col-xs-12 col-md-6 space-xs space-sm space-md space-lg">
                    { title ? <h2 className="space-xs-up">{title}</h2> : null }
                        { text ? <p>{text}</p> : null }
                        <div className="flex middle-xs">
                        { phone ? <PhoneIcon secondary={secondary}/> : null }
                        { phone ? <a style={ primary ? {color: primary} : {color: '#0283f1'} } href={`tel:${phone}`}>{phone}</a> : null }
                        </div>
                        
                    </div>


                    <div className={["col col-xs-12 col-md-6", contactStyles.formWrapper].join(' ')}>
                        <form  name="userEmail" className={contactStyles.form}>
                            <input type="text" name="fullName" placeholder="Dit navn" className={contactStyles.input} onChange={(event) => setFullname({fullName: event.target.value})}/>
                            <input type="tel" name="phone" placeholder="Telefonnummer" className={contactStyles.input} onChange={(event) => setPhone({phone: event.target.value})}/>
                            <input type="email" name="email" placeholder="Email" className={contactStyles.input} onChange={(event) => setEmail({email: event.target.value})}/>
                            <textarea name="assignment" cols="30" rows="8"  placeholder="Beskriv opgaven..." className={contactStyles.input} onChange={(event) => setMessage({message: event.target.value})}/>
                            <button type="submit" className={contactStyles.sendButton} style={{ backgroundColor: primary ? primary : '#ff7b00', color: getContrastColor(primary)}} onClick={submitAssignmentHandler}>
                                {sendButtonText || <EmailIcon/>}
                            </button>
                        </form>
                    </div>
                 
                </div>
            </div>
        </section>
    )
}

export default ContactModern

