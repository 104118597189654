import React from 'react'

const TextBlock = ({textBlocks}) => {

 
  return (
    <>
      {textBlocks.map((block, i) => {
        return (
          <div key={i} className="col col-xs-12 col-md-6 col-lg-4 space-xs-up small text-left">
            <h2 className="h5">{block.title}</h2>
            <p>{block.text}</p>
          </div>
        )
      })}
    </>
  )
  
}

export default TextBlock
