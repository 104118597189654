import React from 'react'

import Classes from './LoadingSpinner.module.scss'

const LoadingSpinner = ({loading, children}) => {

    return (
        <>
        <span className={[Classes.btn, loading ? Classes.loading : null].join(' ')}>
        </span>
        <span className={[Classes.text, loading ? Classes.loading : null].join(' ')}>{children}</span>
        </>
    )
}

export default LoadingSpinner