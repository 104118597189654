import React from 'react'
import whyUsStyles from '../whyUs.module.scss'

const TextBlock = ({textBlocks, secondary, lightColor}) => {

 
  return (
    <>
      {textBlocks.map((block, i) => {
        return (
          <div key={i} className={["col col-xs-12 col-md-6 col-lg-4 space-xs-up text-center flex stretch-md", whyUsStyles.textBlock].join(' ')}>
            <div className={whyUsStyles.textWrapper} style={lightColor ? {backgroundColor: lightColor} : null}>
              <h2 className="h5">{block.title}</h2>
              <p>{block.text}</p>
              {/* {secondary ?
              <div className={whyUsStyles.borderBottom} style={ secondary ? {backgroundColor: secondary} : null }></div>
              : null} */}
            </div>
          </div>
        )
      })}
    </>
  )
  
}

export default TextBlock
